import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'freeRent',
    component: () => import('@/views/freeRent/freeRent.vue')
  },
  {
    path: '/registrationResult',
    name: 'registrationResult',
    component: () => import('@/views/registrationResult/registrationResult.vue')
  },
  {
    path: '/notRegistra',
    name: 'notRegistra',
    component: () => import('@/views/registrationResult/notRegistra.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
