import axios from 'axios'
import {
  Toast,
  Dialog
} from 'vant'
export default {
  install:  (Vue) =>{
    // axios 通用设置
    // axios.defaults.headers.post['Content-type'] = 'application/json;charset=UTF-8';
    axios.defaults.withCredentials = true
    axios.defaults.baseURL = ''
    /**
         * 设置 axios 拦截器
         */
    const setInterceptors =  () =>{
      let loaderInstance = null
      // 用于标记同时多个axios请求的个数，
      // 用于解决同时多个请求的loader效果，
      // 当最后一个请求返回后才取消loader效果
      let simultaneousReqCount = 0
      // 添加请求拦截器
      axios.interceptors.request.use((config)=> {
          // 发送请求之前的统一处理
          // 请求个数+1，默认显示loader效果，除非参数loader值为false
          simultaneousReqCount++
          if (config.loader !== false) {
            loaderInstance = Toast.loading({
              duration: 0,
              forbidClick: true,
              message: '加载中...'
            })
          }
          return config
        }, (error)=> {
          // 对请求错误进行的统一处理
          // 请求出错，请求个数-1
          simultaneousReqCount > 0 && simultaneousReqCount--
          return Promise.reject(error)
        }
      )

      // 添加响应拦截器
      axios.interceptors.response.use(
         (res) =>{
          // 请求返回，请求个数-1
          simultaneousReqCount > 0 && simultaneousReqCount--
          // 如果最后一个请求返回，则关闭loader效果
          if (!simultaneousReqCount || res.config.singleLoader) {
            loaderInstance && loaderInstance.clear()
          }
          // 处理接口返回的多种情况
          switch (Number(res.data.code)) {
            case 100000: // 接口成功返回
              // 默认统一处理返回数据，除非参数needInterceptors值为false
              if (res.config.needInterceptors !== false) {
                return res.data
              } else {
                return Promise.resolve(res) // 原样返回
              }
            default:
              // 接口返回的其他code情况处理
              // 默认显示出错信息，除非参数showError值为false
              if (res.config.showError !== false) {
                const message = res.data.msg || res.data.message
                if (message) {
                  // Dialog.alert({
                  //   title: '提示',
                  //   message: message
                  // })
                  Toast({
                    type: 'text',
                    message:message,
                    duration: 1500
                  })
                }
              }
              // 默认统一处理返回数据，除非参数needInterceptors值为false
              if (res.config.needInterceptors !== false) {
                return Promise.reject({
                  code: res.data.code,
                  message: res.data.msg || res.data.message || '请求出错'
                })
              } else {
                return Promise.resolve(res) // 原样返回
              }
          }
        },
         (error)=> {
          // 请求出错，请求个数-1
          simultaneousReqCount > 0 && simultaneousReqCount--
          // 如果最后一个请求返回，则关闭loader效果
          if (!simultaneousReqCount || error.config.singleLoader) {
            loaderInstance && loaderInstance.clear()
          }
          // 默认显示出错信息，除非参数showError值为false
          if (error.config.showError !== false) {
            Toast({
              type: 'text',
              message: error && error.msg,
              duration: 1500
            })
          }
          // 如果不需要拦截器则返回原始数据
          if (error.config.needInterceptors === false) {
            return Promise.reject(error)
          }

          let result = null
          if (typeof error.code === 'undefined') {
            result = error
          } else {
            result = {
              code: error.code,
              message: error.msg || '请求出错',
              error: error
            }
          }
          return Promise.reject(result)
        }
      )
    }

    /**
         * axios请求封装
         * @param options, 对象类型，各配置参数同axios API中config,
         * 另外还支持 needInterceptors、showError、loader三项配置，默认值都为true
         * @returns {Promise}
         */
    const request = (options) => {
      const config = Object.assign(
        {
          needInterceptors: true,
          showError: true,
          loader: true,
          singleLoader: false
        },
        options
      )
      // 发送请求之前的通用处理逻辑

      return new Promise((resolve, reject) => {
        axios(config)
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    setInterceptors()
    axios.request = request
    Vue.prototype.$axios = axios
  }
}
